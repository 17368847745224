import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';

import { METADATA } from '/src/globals';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const OfflineCaching = (props) => {

  return ( <>

    <Helmet>
      <title>Offline Caching{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>


    <PageWrapper>

      <Typography variant="h1">
        Offline Caching
      </Typography>
      
      <Typography variant="body1">
        Gatsby-plugin-offline creates a service worker that allows the site to work when offline.<br/>
        Note: There is some careful management of the custom file creation function in gatsby-node.js so that it doesn't affect the offline fallback folder that the plugin creates.
      </Typography>

      <Typography variant="body1">
        In gatsby-config.js, when defining the plugin, precachePages can be supplied to tell teh site to download these pages immediately upon the site being visited (rather than caching each page as is visited). All pages are set to do this in the project template but this can be changed.<br/>
        Note: Note: while essential resources of specified pages will be precached, such as JavaScript and CSS, non-essential resources such as fonts and images will not be included. Instead, these will be cached at runtime when a user visits a given page that includes these resources.
      </Typography>

      <Typography variant="body1">
        Note: The service worker doesn't run while developing. You must run "gatsby build" and "gatsby serve" (or deploy the site) to see it in action.<br/>
        Note: Hard reloading doesn't remove the service worker's cache - To clear it you must delete cache and cookies.
        Note: If the service worker needs to be removed from a live site a replacement gatsby plugin will need to be installed to ensure each users browsers purge the cache. <Link to="https://www.gatsbyjs.com/plugins/gatsby-plugin-offline/#remove">Reference</Link>. 
      </Typography>


      <DemoNavigation/>

    </PageWrapper>
    
  </> )

}

OfflineCaching.propTypes = propTypes;
export default OfflineCaching;
